import React from 'react';
import { MapContainer, TileLayer, CircleMarker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function Distribution({ data }) {
  if (!data || data.length === 0) return <p>No data available</p>;

  const minInv = Math.min(...data.map((c) => c.inv_count));
  const maxInv = Math.max(...data.map((c) => c.inv_count));

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <MapContainer
        style={{ height: '100%', width: '100%' }}
        zoom={4}  
        center={[20.5937, 78.9629]} 
        minZoom={3}
        maxZoom={10}
        scrollWheelZoom={true}
        maxBounds={[[-85, -180], [85, 180]]}
        maxBoundsViscosity={1.0}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
          attribution="&copy; <a href='https://carto.com/'>CARTO</a> contributors"
          tileSize={256}
        />
        {data.map((city, index) => {
          const opacity = 1 - ((city.inv_count - minInv) / (maxInv - minInv)) * 0.7;

          return (
            <CircleMarker
              key={index}
              center={[city.coordinates[0], city.coordinates[1]]}
              radius={5 + (15 * (city.inv_count - minInv) / (maxInv - minInv))}
              stroke={false}
              color="#772396"
            >
              <Tooltip direction="top" offset={[0, -5]} opacity={1} permanent={false} interactive={true} sticky={true}>
                <span>{`${city.city}: ${city.inv_count} Investors`}</span>
              </Tooltip>
            </CircleMarker>
          );
        })}
      </MapContainer>
    </div>
  );
}

export default Distribution;
