import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import parse from "html-react-parser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiGetVoteList, apiGetVoteDetails } from "../../utils/api/api";
import { SNACKBAR_ERROR, RECORD_DISPLAY_LIMIT } from "../../utils/constants/constants";
import { apiResendVote, apiCloseVote } from "../../utils/api/api";
import InvestorVotingsDrawer from "./InvestorVotingsDrawer";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { apiVotesDownload } from "../../utils/api/api";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
  Skeleton,
  CircularProgress,
  Divider,
  Link
} from "@mui/material";
import { BASE_URL } from "../../utils/constants/constants";
import GetAppIcon from "@mui/icons-material/GetApp";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { isSubAdmin } from "../../utils/StringUtil";

function InvestorVotingList() {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [voteDetails, setVoteDetails] = useState({});
  const [isDetailsLoading, setIsDetailsLoading] = useState(false)
  const [activeVoteDetails, setActiveVoteDetails] = useState([]);
  const [pastVoteDetails, setPastVoteDetails] = useState([]);
  const userType = process.env.REACT_APP_USER_TYPE;
  const [actionLoader, setActionLoader] = useState(false);

  useEffect(() => {
    getVoteList();
  }, []);

  const getVoteList = () => {
    setIsLoading(true);
    apiGetVoteList()
      .then((data) => {
        setIsLoading(false);
        setActiveVoteDetails(data.activeVotingList);
        setPastVoteDetails(data.pastVotingList);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    expanded !== null && getVoteDetails();
  }, [expanded]);

  const getVoteDetails = (param = {}) => {
    setIsDetailsLoading(true);
    param.voteRid = expanded;
    apiGetVoteDetails(param)
      .then((data) => {
        setIsDetailsLoading(false);
        setVoteDetails(data);
      })
      .catch((error) => {
        setIsDetailsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const handleResend = async (voteRid) => {
    if (!voteRid) {
      enqueueSnackbar("Invalid vote ID.", SNACKBAR_ERROR);
      return;
    }

    setActionLoader(true);
    try {
      await apiResendVote(voteRid);
      enqueueSnackbar("Resent the voting email to investors who have not yet voted.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error resending.", SNACKBAR_ERROR);
    } finally {
      setActionLoader(false);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  }

  const handleSaveVote = ()=>{
    setOpen(false);
    setTimeout(() => {
      getVoteList();
    }, 1000);
  }

  const handleClose = async (voteRid) => {
    if (!voteRid) {
      enqueueSnackbar("Invalid vote ID.", SNACKBAR_ERROR);
      return;
    }

    setActionLoader(true);
    try {
      await apiCloseVote(voteRid);
      getVoteList()
      enqueueSnackbar("Vote closed successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error closing.", SNACKBAR_ERROR);
    } finally {
      setActionLoader(false);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const downloadFile = async (response) => {
    const contentType = response.headers.get('content-type');
    const contentDisposition = response.headers.get('content-disposition');

    if (!contentType || !contentDisposition) {
      console.error("Missing headers in response", response.headers);
      return;
    }

    const blob = new Blob([response.data], { type: contentType });
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    const fileName = contentDisposition.split('"')[1] || 'downloaded-file';
    downloadLink.setAttribute('download', fileName);
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  };

  const downloadVoteDetails = (voteId) => {
    const params = {
      voteRid: voteId
    };

    apiVotesDownload(params)
      .then((data) => {
        downloadFile(data);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const pendingVote = voteDetails?.votePercentageDetails?.find(option => option.label === "Pending");

  const totalVotePercentage = voteDetails?.votePercentageDetails
    ?.filter(option => option.label !== "Pending")
    .reduce((sum, option) => sum + (option.votePercentage || 0), 0);

  const isVoteCompleted =
    !pendingVote?.votePercentage ||
    pendingVote?.votePercentage === "" ||
    totalVotePercentage === 100;

  const maxLabelLength = Math.max(...(voteDetails.votePercentageDetails?.map(({ label }) => label.length) || [0]));

  const labelWidth = `${maxLabelLength * 9.2}px`;

  return (
    <Layout title="Investor Voting">
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            zIndex: 10,
            bottom: "20px",
            right: "20px",
          }}
          onClick={() => setOpen(true)}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}
      <div>
        <div className="p-4" style={{ marginTop: "5px", marginLeft: "2rem", marginRight: "2rem" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangular"
                height={40}
              />
            ))
          ) : (
            <Stack>
              <p className="fs-large-semibold">Active Votes</p>
              {activeVoteDetails?.map((data) => (
                <Accordion
                  key={data.voteRid}
                  expanded={expanded === data.voteRid}
                  onChange={handleChange(data.voteRid)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls={`panel-${data.voteRid}-content`}
                    id={`panel-${data.voteRid}-header`}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <h5 className="fs-med-semibold p-0 m-0">{data.subject}</h5>
                      <Button
                        sx={{ textTransform: "none" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadVoteDetails(data.voteRid);
                        }}
                        startIcon={<GetAppIcon />}
                        disabled={isLoading}
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isDetailsLoading ? (
                      <CircularProgress />
                    ) : (
                      <div>
                        {expanded === data?.voteRid && voteDetails ? (
                          <div>
                            <p className="fs-med-medium">{parse(voteDetails?.voteDetail?.body || "", {
                              replace: (domNode) => {
                                if (domNode.name === "img") {
                                  return (
                                    <img
                                      src={domNode.attribs.src}
                                      alt={domNode.attribs.alt || "Image"}
                                      style={{ maxWidth: "100%", height: "auto" }}
                                    />
                                  );
                                }
                              }
                            })}</p>
                            <Box sx={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                              <p className="fs-med-medium" style={{ marginBottom: "0rem",fontWeight: "bold", }}>Documents:</p>
                              {voteDetails?.voteDetail?.attachment?.length > 0 ? (
                                voteDetails?.voteDetail?.attachment
                                  .filter(Boolean)
                                  .map((data, key) =>
                                    data?.actualFileName ? (
                                      <Stack title={data.actualFileName} key={key} style={{ flexDirection: "column", display: "flex" }}>
                                        <>
                                          <Link
                                            color="secondary"
                                            target="_blank"
                                            href={`${BASE_URL}/${data.path}`}
                                            sx={{ marginLeft: "5px" }}
                                          >
                                            <AttachFileIcon
                                              color="secondary"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Link>
                                        </>
                                      </Stack>
                                    ) : null
                                  )
                              ) : (
                                <Typography>No Attachments</Typography>
                              )}
                            </Box>
                            <Box sx={{ marginTop: "10px" }}>
                              {voteDetails.votePercentageDetails?.map(({ label, votePercentage }, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    marginBottom: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      width: labelWidth,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {label}
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      width: "20px",
                                      textAlign: "center"
                                    }}
                                  >
                                    {" : "}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      flexGrow: 1,
                                      textAlign: "left"
                                    }}
                                  >
                                    {votePercentage?.toFixed(2) > 0 ? votePercentage?.toFixed(2) : 0}%
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                            <div style={{ disply: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center", marginTop: "20px" }}>
                              {data.isClosed !== 1 ? (
                                <>
                                  <Tooltip title={isVoteCompleted ? "Can't resend. Vote is completed" : ""} disableHoverListener={!isVoteCompleted}>
                                    <span>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        sx={{ color: "white" }}
                                        onClick={() => {
                                          if (isVoteCompleted || actionLoader) return;
                                          handleResend(voteDetails?.voteDetail?.voteRid);
                                        }}
                                        disabled={isVoteCompleted || actionLoader}
                                        style={{ marginRight: "10px" }}
                                      >
                                        Resend
                                      </Button>
                                    </span>
                                  </Tooltip>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ color: "white", }}
                                    onClick={() => handleClose(voteDetails?.voteDetail?.voteRid)}
                                    disabled={actionLoader}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Close
                                  </Button>
                                </>
                              ) : (
                                <div>
                                  <p style={{ color: "red" }} className="fs-large-semibold">This vote is already closed</p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <p className="fs-med-regular p-0 m-0">No Data</p>
                        )}
                      </div>)}
                  </AccordionDetails>
                </Accordion>
              ))}
              <Divider style={{ height: "1px", backgroundColor: "rgba(0, 0, 0, 0.12)", marginTop: "40px" }} />
              <p className="fs-large-semibold" style={{ marginTop: "20px" }}>Past Votes</p>
              {pastVoteDetails?.map((data) => (
                <Accordion key={data.voteRid}
                  expanded={expanded === data.voteRid}
                  onChange={handleChange(data.voteRid)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls={`panel-${data.voteRid}-content`}
                    id={`panel-${data.voteRid}-header`}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <h5 className="fs-med-semibold p-0 m-0">{data.subject}</h5>
                      <Button
                        sx={{ textTransform: "none" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadVoteDetails(data.voteRid);
                        }}
                        startIcon={<GetAppIcon />}
                        disabled={isLoading}
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {expanded === data?.voteRid && voteDetails ? (
                      <div>
                        <p className="fs-med-medium">{parse(voteDetails?.voteDetail?.body || "", {
                          replace: (domNode) => {
                            if (domNode.name === "img") {
                              return (
                                <img
                                  src={domNode.attribs.src}
                                  alt={domNode.attribs.alt || "Image"}
                                  style={{ maxWidth: "100%", height: "auto" }}
                                />
                              );
                            }
                          }
                        })}</p>
                        <Box sx={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                          <p className="fs-med-medium" style={{ marginBottom: "0rem" }}>Documents:</p>
                          {voteDetails?.voteDetail?.attachment?.length > 0 ? (
                            voteDetails?.voteDetail?.attachment
                              .filter(Boolean)
                              .map((data, key) =>
                                data?.actualFileName ? (
                                  <Stack title={data.actualFileName} key={key} style={{ flexDirection: "column", display: "flex" }}>
                                    <>
                                      <Link
                                        color="secondary"
                                        target="_blank"
                                        href={`${BASE_URL}/${data.path}`}
                                        sx={{ marginLeft: "5px" }}
                                      >
                                        <AttachFileIcon
                                          color="secondary"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Link>
                                    </>
                                  </Stack>
                                ) : null
                              )
                          ) : (
                            <Typography>No Attachments</Typography>
                          )}
                        </Box>
                        <Box sx={{ marginTop: "10px" }}>
                          {voteDetails.votePercentageDetails?.map(({ label, votePercentage }, index) => (
                            <Box
                              key={index}
                              sx={{
                                marginBottom: "8px",
                                display: "flex",
                                width: "100%",
                                alignItems: "center"
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  flex: 1,
                                }}
                              >
                                {label}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  width: "10px",
                                  textAlign: "center"
                                }}
                              >
                                :
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  flex: 1,
                                  textAlign: "left"
                                }}
                              >
                                {votePercentage?.toFixed(2) > 0 ? votePercentage?.toFixed(2) : 0}%
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                        <div style={{ disply: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center", marginTop: "20px" }}>
                          {data.isClosed !== 1 ? (
                            <>
                              <Tooltip title={isVoteCompleted ? "Can't resend. Vote is completed" : ""} disableHoverListener={!isVoteCompleted}>
                                <span>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ color: "white" }}
                                    onClick={() => {
                                      if (isVoteCompleted || actionLoader) return;
                                      handleResend(voteDetails?.voteDetail?.voteRid);
                                    }}
                                    disabled={isVoteCompleted || actionLoader}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Resend
                                  </Button>
                                </span>
                              </Tooltip>
                              <Button
                                variant="contained"
                                color="secondary"
                                sx={{ color: "white", }}
                                onClick={() => handleClose(voteDetails?.voteDetail?.voteRid)}
                                disabled={actionLoader}
                                style={{ marginRight: "10px" }}
                              >
                                Close
                              </Button>
                            </>
                          ) : (
                            <div>
                              <p style={{ color: "red" }} className="fs-large-semibold">This vote is already closed</p>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="fs-med-regular p-0 m-0">No Data</p>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}

              <InvestorVotingsDrawer open={open} onClose={handleCloseModal} handleSave={handleSaveVote} />
            </Stack>)}
        </div>
      </div>
    </Layout>
  );
}

export default InvestorVotingList;
