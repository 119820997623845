import React, { useState, useRef } from 'react';
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSnackbar } from 'notistack';
import { apiUploadUnitAllotmentFiles } from '../../utils/api/api';
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '../../utils/constants/constants';

const FileUploadDialog = ({ open, type, selectedRecord, onUpload, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      setSelectedFile(file);
      setFileName(file.name);
      if (fileInputRef.current) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        fileInputRef.current.files = dataTransfer.files;
      }
    }
  };

  const clearFileSelection = () => {
    setSelectedFile(null);
    setFileName('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleUpload = () => {
    if (!selectedFile) {
      enqueueSnackbar('Please select a file', SNACKBAR_ERROR);
      return;
    }
    if (!selectedRecord || !selectedRecord.utRid || !selectedRecord.invOppRid) {
      enqueueSnackbar('Required record information is missing', SNACKBAR_ERROR);
      return;
    }
    setIsLoading(true);
    apiUploadUnitAllotmentFiles(
      selectedRecord.utRid,
      type,
      selectedRecord.invOppRid,
      selectedFile
    )
      .then((response) => {
        setIsLoading(false);
        enqueueSnackbar(response.message, SNACKBAR_SUCCESS);
        onUpload(response.data);
        clearFileSelection();
        onClose();
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error.message || 'Upload failed', SNACKBAR_ERROR);
      });
  };
  const handleDialogClose = () => {
    clearFileSelection();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ pl: 4.8 }}>
        Upload {type === 'seller' ? 'Seller' : 'Buyer'} Unit Allotment Statement
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 2 }}>
          <Box
            sx={{
              border: `2px dashed ${isDragActive ? 'primary.main' : '#ccc'}`,
              borderRadius: 2,
              p: 3,
              textAlign: 'center',
              backgroundColor: isDragActive ? 'rgba(0, 0, 0, 0.04)' : '#fafafa',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: '#f5f5f5'
              }
            }}
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              ref={fileInputRef}
              id="file-input"
              type="file"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
              accept=".pdf,.doc,.docx"
            />
            <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
            <Typography variant="body1" gutterBottom>
              {fileName || (isDragActive
                ? 'Drop your file here'
                : 'Click to select a file or drag and drop here')}
            </Typography>
            {selectedFile && (
              <Typography variant="body2" color="text.secondary">
                File size: {(selectedFile.size / 1024).toFixed(2)} KB
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            clearFileSelection();
            onClose();
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!selectedFile || isLoading}
          sx={{ mr: 2 }}
        >
          {isLoading ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;