import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";
import Layout from "../../components/layout/Layout";
import Pagination from "@mui/material/Pagination";
import _ from "lodash";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Typography } from "@mui/material";
import { RECORD_DISPLAY_LIMIT } from "../../utils/constants/constants";
import {
  Chip,
  CircularProgress,
  Skeleton,
  Tooltip,
  tooltipClasses,
  Tab,
  Tabs,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import {
  apiDematTableInvestors,
  getDematMasterTable, getOpportunitySimple, apiDematHoldingsDownload, getPartners,
  apiGetDematHoldings,
} from "../../utils/api/api";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { SNACKBAR_ERROR } from "../../utils/constants/constants";
import { isSubAdmin } from "../../utils/StringUtil";
import Button from "@mui/material/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Autocomplete, TextField } from "@mui/material";
import { OPEN_SCHEMES, CLOSED_SCHEMES } from "../../utils/constants/constants";
import { CREDITED,PENDING,ESCROW } from "../../utils/constants/constants";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    border: "1px solid #000",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
  },
}));

export const DematInvestors = ({ dematInvestorList, showTableLoading }) => {
  return (
    <div
      className="d-flex flex-column overflow-y-auto"
      style={{ minWidth: "300px", maxHeight: "500px" }}
    >
      {showTableLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dematInvestorList?.map((investor, index) => (
            <div className="d-flex align-items-center px-2 py-1" key={index}>
              <AccountCircleIcon color="primary" fontSize="large" />
              <div className="d-flex flex-grow-1 flex-column ms-2">
                <div>
                  <span className="fs-med-medium text-dark">
                    {investor?.fullName}
                  </span>
                  <span className="fs-med-medium ms-1">
                    <Chip
                      label={investor?.dematCreditDate ? "CREDITED" : "PENDING"}
                      size="small"
                      color={investor?.dematCreditDate ? "success" : "warning"}
                      variant="outlined"
                      sx={{
                        fontSize: "0.7rem",
                        height: "20px",
                        padding: "4px",
                      }}
                    />
                  </span>
                </div>
                <span className="fs-small-regular text-dark">
                  {investor?.email}
                </span>
                <span className="fs-small-medium text-dark">
                  {investor?.invCode}
                </span>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const DematMaster = () => {
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dematList, setDematList] = useState([]);
  const [dematDataList, setDematDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [dematInvestors, setDematInvestors] = useState([]);
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [filterPartner, setFilterPartner] = useState([]);
  const [filterScheme, setFilterScheme] = useState([]);
  const [filterSchemeList, setFilterSchemeList] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const userType = process.env.REACT_APP_USER_TYPE;
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [holdingsList, setHoldingsList] = useState([]);
  const [persistentSchemeFilters, setPersistentSchemeFilters] = useState([]);
  const [schemeFilterLoading, setSchemeFilterLoading] = useState(false);
  const [filterDematStatus, setFilterDematStatus] = useState("all");
  const [schemePage, setSchemePage] = useState(1);
  const [hasMoreSchemes, setHasMoreSchemes] = useState("");
  const schemeListboxRef = useRef();
  const loaderRef = useRef(null);
  const [initialTabLoad, setInitialTabLoad] = useState(true);
  const [schemeSearchTerm, setSchemeSearchTerm] = useState("");
  const [isSearchingScheme, setIsSearchingScheme] = useState(false);
  const scrollPreservationRef = useRef({
    position: 0,
    isUpdating: false
  });
  const lastDematHoldingsParams = useRef(null);
  const lastFilterSchemesParams = useRef(null);
  const filterChangeRef = useRef(false);

  const schemeAutocompleteRef = useRef(null);
  const schemeInputPositionRef = useRef(null);
  const debouncedSearch = useCallback(
    _.debounce((searchValue) => {
      setSearchTerm(searchValue);
    }, 100),
    []
  );

  const handleSearch = (value) => {
    debouncedSearch(value);
  };

  const downloadFile = async (response) => {
    const blob = new Blob([response.data], { type: response.headers.get('content-type') });
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.setAttribute('download', response.headers.get('content-disposition').split('"')[1]);
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  }

  const getDematInvestors = async (params) => {
    setShowTableLoader(true);
    try {
      const res = await apiDematTableInvestors({
        sd: params?.dpType,
        oppRid: params?.oppRid,
        cmRid: params?.cmRid,
      });
      setDematInvestors(res);
      setShowTableLoader(false);
    } catch (error) {
      console.error("Error fetching demat data:", error);
      setShowTableLoader(false);
    }
  };

  const loadMoreSchemes = () => {
    if (!schemeListboxRef.current || schemeFilterLoading) return;
    const listBox = schemeListboxRef.current;
    const isAtBottom = listBox.scrollHeight - listBox.scrollTop <= listBox.clientHeight + 20;
    if (isAtBottom && hasMoreSchemes === "") {
      scrollPreservationRef.current = {
        position: listBox.scrollTop,
        isUpdating: true
      };
      setSchemeFilterLoading(true);
      const nextPage = schemePage + 1;
      setSchemePage(nextPage);
      getFilterSchemes(nextPage, true, schemeSearchTerm);
    }
  };

  const debouncedSchemeSearch = useCallback(
    _.debounce((searchValue) => {
      const input = schemeAutocompleteRef.current?.querySelector('input[role="combobox"]');
      const cursorPosition = input?.selectionStart || searchValue.length;
      schemeInputPositionRef.current = cursorPosition;
      setSchemeSearchTerm(searchValue);
      setSchemePage(1);
      setHasMoreSchemes("");
      getFilterSchemes(1, false, searchValue)
        .finally(() => {
          setTimeout(() => {
            const input = schemeAutocompleteRef.current?.querySelector('input[role="combobox"]');
            if (input) {
              input.focus();
              const position = schemeInputPositionRef.current || searchValue.length;
              input.setSelectionRange(position, position);
            }
          }, 10);
        });
    }, 300),
    [filterPartner]
  );
  const getFilterSchemes = async (page = 1, loadMore = false, searchTerm = "") => {
    if (schemeFilterLoading && !loadMore) return Promise.resolve();

    const params = {
      page: page,
      limit: 20,
      schemeStatus: `${OPEN_SCHEMES},${CLOSED_SCHEMES}`,
      schemeName: searchTerm,
      partnerRids: filterPartner.length > 0 ? filterPartner.join(",") : ""
    };
    if (
      !loadMore &&
      lastFilterSchemesParams.current &&
      JSON.stringify(params) === JSON.stringify(lastFilterSchemesParams.current)
    ) {
      return Promise.resolve();
    }
    lastFilterSchemesParams.current = params;
    if (!loadMore) {
      setSchemeFilterLoading(true);
    }
    try {
      const response = await getOpportunitySimple(params);
      if (response?.opportunityList && response.opportunityList.length > 0) {
        const schemes = response.opportunityList.map(opportunity => ({
          schemeRid: opportunity.oppRid,
          schemeName: opportunity.oppTitle
        }));

        if (loadMore) {
          setFilterSchemeList(prevList => {
            const allSchemes = [...prevList, ...schemes];
            const uniqueSchemes = Array.from(
              new Set(allSchemes.map(scheme => scheme.schemeRid))
            ).map(rid =>
              allSchemes.find(scheme => scheme.schemeRid === rid)
            );
            return uniqueSchemes.sort((a, b) => a.schemeName.localeCompare(b.schemeName));
          });
        } else {
          setFilterSchemeList(
            schemes.sort((a, b) => a.schemeName.localeCompare(b.schemeName))
          );
        }

        if (response.opportunityList.length < params.limit ||
          page * params.limit >= response.totalCount) {
          setHasMoreSchemes("true");
        } else {
          setHasMoreSchemes("");
        }
      } else {
        setHasMoreSchemes("true");
      }

      return Promise.resolve();
    } catch (error) {
      console.error("Error fetching filter schemes:", error);
      enqueueSnackbar(error, SNACKBAR_ERROR);
      return Promise.reject(error);
    } finally {
      setSchemeFilterLoading(false);
    }
  };
  useLayoutEffect(() => {
    if (schemeListboxRef.current && scrollPreservationRef.current.isUpdating && !schemeFilterLoading) {
      schemeListboxRef.current.scrollTop = scrollPreservationRef.current.position;
      scrollPreservationRef.current.isUpdating = false;
    }
  }, [filterSchemeList, schemeFilterLoading]);
  
  const DematStatusFilter = ({ filterDematStatus, setFilterDematStatus, isLoading, onStatusChange }) => {
    const getStatusColor = (status) => {
      switch (status) {
        case CREDITED:
          return '#2E7D32';
        case PENDING:
          return '#FF7518';
        case ESCROW:
          return '#b71c1c';
        default:
          return '#00000000';
      }
    };

    const handleChange = (event) => {
      const newStatus = event.target.value;
      setPage(1);
      setFilterDematStatus(newStatus);
      if (onStatusChange) {
        onStatusChange(newStatus);
      }
    };

    return (
      <FormControl sx={{ width: 150 }} size="small">
        <InputLabel id="demat-status-select-label">Demat Status</InputLabel>
        <Select
          labelId="demat-status-select-label"
          id="demat-status-select"
          value={filterDematStatus}
          label="Demat Status"
          onChange={handleChange}
          disabled={isLoading}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {(selected === CREDITED || selected === PENDING || selected === ESCROW) && (
                <FiberManualRecordIcon
                  sx={{
                    fontSize: 15,
                    color: getStatusColor(selected)
                  }}
                />
              )}
              <Typography variant="body2">
                {selected === CREDITED ? 'Credited' :
                  selected === PENDING ? 'Pending' :
                    selected === ESCROW ? 'Escrow' : 'All'}
              </Typography>
            </Box>
          )}
        >
          <MenuItem value="all">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2">All</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={CREDITED}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FiberManualRecordIcon sx={{ fontSize: 14, color: '#2E7D32' }} />
              <Typography variant="body2">Credited</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={PENDING}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FiberManualRecordIcon sx={{ fontSize: 14, color: '#FF7518' }} />
              <Typography variant="body2">Pending</Typography>
            </Box>
          </MenuItem>
          <MenuItem value={ESCROW}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FiberManualRecordIcon sx={{ fontSize: 14, color: '#b71c1c' }} />
              <Typography variant="body2">Escrow</Typography>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  const getDematList = async () => {
    const params = {
      page,
      limit: RECORD_DISPLAY_LIMIT
    };
    setIsLoading(true);
    try {
      const data = await getDematMasterTable(params);
      setDematList(data.tableColumn);
      setDematDataList(data.tableData);
      if (typeof data.records === 'number') {
        setTotalRecords(data.records);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching demat data:", error);
      enqueueSnackbar(error, SNACKBAR_ERROR);
      setTotalRecords(0);
      setIsLoading(false);
    }
  };

  const getDematHoldings = async () => {
    const params = {
      page: page,
      limit: RECORD_DISPLAY_LIMIT,
      invName: searchTerm,
      partnerRids: filterPartner.length > 0 ? filterPartner.join(",") : "",
      schemeRids: filterScheme.length > 0 ? filterScheme.join(",") : "",
      dematStatus: filterDematStatus !== "all" ? filterDematStatus : "",
    };
    if (
      lastDematHoldingsParams.current &&
      JSON.stringify(params) === JSON.stringify(lastDematHoldingsParams.current)
    ) {
      return;
    }
    lastDematHoldingsParams.current = params;
    setIsLoading(true);
    try {
      const response = await apiGetDematHoldings(params);
      setHoldingsList(response.holdingsList);

      if (page === 1 && typeof response.records === "number") {
        setTotalRecords(response.records);
      }
    } catch (error) {
      console.error("Error fetching demat holdings:", error);
      enqueueSnackbar(error, SNACKBAR_ERROR);
      setTotalRecords(0);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSchemeFilterChange = (event) => {
    const newFilters = event.target.value;
    setPage(1);
    setFilterScheme(newFilters);
    setPersistentSchemeFilters(newFilters);
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const downloadDematHoldings = () => {
    const params = {
      invName: searchTerm && searchTerm.trim() !== "" ? searchTerm.trim() : "",
      partnerRids: filterPartner.length > 0 ? filterPartner.join(",") : null,
      schemeRids: filterScheme.length > 0 ? filterScheme.join(",") : null,
      dematStatus: filterDematStatus !== "all" ? filterDematStatus : "",
    };

    apiDematHoldingsDownload(params)
      .then((data) => {
        downloadFile(data);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const displayTableValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return '---';
    }
    return value;
  };

  const formatNumber = (number) => {
    if (number === null || number === undefined) {
      return '---';
    }
    return number.toLocaleString() || '---';
  };

  const getPartnersList = useCallback(() => {
    setPartnerList([]);
    getPartners()
      .then((res) => {
        setPartnerList(res.partnerMasterList);
      })
      .catch((e) => {
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    getPartnersList();
  }, [getPartnersList]);

  useEffect(() => {
    if (tabIndex === 0) {
      getDematList();
    } else if (tabIndex === 1) {
      setInitialTabLoad(true);
      getFilterSchemes(1, false, schemeSearchTerm);
      getDematHoldings();
      setPage(1);
      setSchemePage(1);
      setHasMoreSchemes("");
    }
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex === 1 && !initialTabLoad) {
      setSchemePage(1);
      setHasMoreSchemes("");
      getFilterSchemes(1, false, schemeSearchTerm);
    }
    if (initialTabLoad && tabIndex === 1) {
      setInitialTabLoad(false);
    }
  }, [tabIndex, filterPartner, initialTabLoad]);

  useEffect(() => {

    if (tabIndex === 1 && page >= 1) {

      getDematHoldings();

    }

  }, [tabIndex, page]);

  useEffect(() => {
    if (tabIndex === 0) {
      getDematList();
    } else if (tabIndex === 1) {
      setInitialTabLoad(true);
      setPage(1);
      setSchemePage(1);
      setHasMoreSchemes("");
      getFilterSchemes(1, false, schemeSearchTerm)
        .then(() => {
          if (!lastDematHoldingsParams.current) {
            getDematHoldings();
          }
        });
    }
  }, [tabIndex]);
  useEffect(() => {
    if (tabIndex === 1 && !initialTabLoad) {
      const params = {
        page: page,
        limit: RECORD_DISPLAY_LIMIT,
        invName: searchTerm,
        partnerRids: filterPartner.length > 0 ? filterPartner.join(",") : "",
        schemeRids: filterScheme.length > 0 ? filterScheme.join(",") : "",
        dematStatus: filterDematStatus !== "all" ? filterDematStatus : "",
      };
      if (
        lastDematHoldingsParams.current &&
        JSON.stringify(params) === JSON.stringify(lastDematHoldingsParams.current)
      ) {
        return;
      }

      setPage(1);
      getDematHoldings();
    }

    if (initialTabLoad && tabIndex === 1) {
      setInitialTabLoad(false);
    }
  }, [tabIndex, filterPartner, filterScheme, filterDematStatus, searchTerm, initialTabLoad]);

  useEffect(() => {
    if (tabIndex === 1 && !initialTabLoad && filterPartner.length > 0) {
      const params = {
        page: 1,
        limit: 20,
        schemeStatus: `${OPEN_SCHEMES},${CLOSED_SCHEMES}`,
        schemeName: schemeSearchTerm,
        partnerRids: filterPartner.length > 0 ? filterPartner.join(",") : ""
      };
      if (
        lastFilterSchemesParams.current &&
        JSON.stringify(params) === JSON.stringify(lastFilterSchemesParams.current)
      ) {
        return;
      }

      setSchemePage(1);
      setHasMoreSchemes("");
      getFilterSchemes(1, false, schemeSearchTerm);
    }
  }, [tabIndex, filterPartner, initialTabLoad]);
  useEffect(() => {
    if (tabIndex === 1 && page > 1) {
      getDematHoldings();
    }
  }, [tabIndex, page]);
  const styles = {
    stickyColumn: {
      position: "sticky",
      left: 0,
      zIndex: 1,
    },
    stickyTop: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    stickyColumn2: {
      position: "sticky",
      left: "250px",
      zIndex: 1,
    },
    stickyColumn3: {
      position: "sticky",
      left: "400px",
      zIndex: 1,
    },
  };
  const renderTableContent = () => {
    if (tabIndex === 0) {
      return (
        <div className="overflow-x-scroll">
          {dematList?.length > 0 ? (
            <table
              className="table table-striped table-borderless w-100 position-relative"
              id="order-table"
            >
              <thead>
                <tr className="fs-normal-semibold text-center sticky-table-row">
                  <th
                    className="sticky-table-cell"
                    scope="col"
                    style={{ minWidth: "250px" }}
                  >
                    <p className="m-0">Scheme Names</p>
                  </th>
                  {dematList.map((item, key) => (
                    <th
                      key={key}
                      scope="col"
                      style={{ minWidth: "150px" }}
                      className="text-center"
                    >
                      <p
                        className={`${item.dpType === "ESCROW" ? "text-danger" : ""
                          } m-0`}
                      >
                        {item.name} {item.dpType}
                      </p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-table-blue">
                {dematDataList.map((data, index) => (
                  <tr key={index} className="fs-normal-medium text-center">
                    <td className="sticky-table-cell">{data.schemeName}</td>
                    {data.cells.map((cellData, cellIndex) => (
                      <td className="text-center" key={cellIndex}>
                        {cellData?.dpType === null ? (
                          <span>-</span>
                        ) : cellData?.value === 0 ? (
                          <span
                            className={`${cellData.dpType === "ESCROW" ? "text-danger" : ""
                              }`}
                          >
                            {cellData.value}
                          </span>
                        ) : (
                          <CustomTooltip
                            title={
                              <DematInvestors
                                dematInvestorList={dematInvestors}
                                showTableLoading={showTableLoader}
                              />
                            }
                            arrow
                          >
                            <span
                              className={`${cellData.dpType === "ESCROW"
                                ? "text-danger"
                                : ""
                                }`}
                              style={{ cursor: "pointer" }}
                              onMouseEnter={() => getDematInvestors(cellData)}
                            >
                              {cellData.value}
                            </span>
                          </CustomTooltip>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No Data..</p>
          )}
        </div>
      );
    }



    if (tabIndex === 1) {
      return (
        <div className="d-flex overflow-x-auto">
          {!Array.isArray(holdingsList) || holdingsList.length === 0 ? (
            <p>No Data..</p>
          ) : (
            <table
              className="table table-borderless w-auto"
              id="order-table"
            >
              <thead>
                <tr
                  className="fs-normal-semibold text-center"
                  style={styles.stickyTop}
                >
                  <th
                    className="sticky-column"
                    style={{ ...styles.stickyColumn }}
                  >
                    <p className="m-0">Investor Name</p>
                  </th>
                  <th
                    className="sticky-column"
                    style={{ ...styles.stickyColumn2 }}
                  >
                    <p className="m-0">DP ID</p>
                  </th>
                  <th
                    className="sticky-column"
                    style={{ ...styles.stickyColumn3 }}
                  >
                    <p className="m-0">Client ID</p>
                  </th>
                  <th scope="col">
                    <p className="m-0">Scheme Name</p>
                  </th>
                  <th scope="col">
                    <p className="m-0">Class</p>
                  </th>
                  <th scope="col">
                    <p className="m-0">No of Units</p>
                  </th>
                  <th scope="col">
                    <p className="m-0">Demat Status</p>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-table-blue">
                {holdingsList.map((investor, investorIndex) => {
                  const previousRows = holdingsList
                    .slice(0, investorIndex)
                    .reduce((sum, inv) => sum + inv.dematDetailList.length, 0);

                  return (
                    <React.Fragment key={investor.invRid}>
                      {investor.dematDetailList.map((detail, detailIndex) => {
                        const absoluteRowIndex = previousRows + detailIndex;
                        const stripeBackground = absoluteRowIndex % 2 === 0 ? '#F2F2F2' : '#ffffff';
                        const isLastDetailRow = detailIndex === investor.dematDetailList.length - 1;

                        return (
                          <tr
                            key={`${investor.invRid}-${detailIndex}`}
                            className="fs-normal-medium text-center"
                            style={{
                              borderBottom: isLastDetailRow ? '1.5px solid #e0e0e0' : 'none'
                            }}
                          >
                            {detailIndex === 0 && (
                              <>
                                <td
                                  className="sticky-column"
                                  style={{
                                    ...styles.stickyColumn,
                                    minWidth: "250px",
                                    backgroundColor: investorIndex % 2 === 0 ? '#F2F2F2' : '#ffffff',
                                    verticalAlign: 'middle'
                                  }}
                                  rowSpan={investor.dematDetailList.length}
                                >
                                  <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'flex-start',
                                    gap: '4px'
                                  }}>
                                    <div style={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      alignItems: 'baseline',
                                      maxWidth: '100%'
                                    }}>
                                      <span style={{
                                        marginRight: '8px',
                                        wordBreak: 'break-word'
                                      }}>
                                        {displayTableValue(investor.invName)}
                                      </span>
                                      <span style={{
                                        fontSize: '10px',
                                        color: '#666666',
                                        flexShrink: 0,
                                        alignSelf: 'flex-start',
                                        marginTop: '4px'
                                      }}>
                                        {investor.invCode}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className="sticky-column"
                                  style={{
                                    ...styles.stickyColumn2,
                                    minWidth: "150px",
                                    backgroundColor: investorIndex % 2 === 0 ? '#F2F2F2' : '#ffffff',
                                    verticalAlign: 'middle'

                                  }}
                                  rowSpan={investor.dematDetailList.length}
                                >
                                  {displayTableValue(investor.dpId)}
                                </td>
                                <td
                                  className="sticky-column"
                                  style={{
                                    ...styles.stickyColumn3,
                                    minWidth: "150px",
                                    backgroundColor: investorIndex % 2 === 0 ? '#F2F2F2' : '#ffffff',
                                    verticalAlign: "middle"

                                  }}
                                  rowSpan={investor.dematDetailList.length}
                                >
                                  {displayTableValue(investor.clientId)}
                                </td>
                              </>
                            )}
                            <td style={{ minWidth: "200px", backgroundColor: stripeBackground }}>
                              {displayTableValue(detail.schemeName)}
                            </td>
                            <td style={{ minWidth: "150px", backgroundColor: stripeBackground }}>
                              {displayTableValue(detail.className)}
                            </td>
                            <td style={{ minWidth: "150px", textAlign: "center", backgroundColor: stripeBackground }}>
                              {formatNumber(detail.noOfUnits)}
                            </td>
                            <td style={{ minWidth: "200px", backgroundColor: stripeBackground }}>
                              {detail.dematStatus === ESCROW ? (
                                <Chip
                                  label="ESCROW"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#d32f2f",
                                    color: "white",
                                    borderColor: "#b71c1c",
                                    fontWeight: "medium",
                                    "& .MuiChip-label": {
                                      color: "white"
                                    }
                                  }}
                                />
                              ) : (
                                <Chip
                                  label={detail.dematStatus}
                                  size="small"
                                  color={
                                    detail.dematStatus === "Credited"
                                      ? "success"
                                      : "warning"
                                  }
                                  variant="outlined"
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      );
    }
  }

  return (
    <Layout title="Demat">
      <Box paddingTop="10px" display="flex" alignItems="center">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="DEMAT MASTER" />
          <Tab label="DEMAT HOLDINGS" />
        </Tabs>
      </Box>
      <Divider style={{ height: "1px", backgroundColor: "#000" }} />
      {tabIndex === 1 && (
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div className="d-flex align-items-center gap-2 flex-wrap">
            <div
              className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
              style={{
                border: "1px solid #000",
                minWidth: "200px",
                flex: "1 1 auto",
              }}
            >
              <div className="my-auto">
                <SearchIcon sx={{ cursor: "pointer" }} />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="form-control custom-form-control border-0 bg-transparent fs-large-regular"
                placeholder="Search"
              />
              <div onClick={() => setSearchTerm("")} className="my-auto">
                <CloseIcon sx={{ cursor: "pointer" }} />
              </div>
            </div>
            <div className="d-flex gap-2 flex-wrap">

              {userType === "ADMIN" && (
                <FormControl
                  sx={{
                    width: { xs: "100%", sm: 180 },
                    minWidth: "180px",
                    "@media (max-width: 600px)": {
                      width: "100%",
                    },
                  }}
                  size="small"
                >
                  <Autocomplete
                    multiple
                    id="partner-filter-autocomplete"
                    options={partnerList}
                    getOptionLabel={(option) => option.fullName}
                    value={partnerList.filter(partner => filterPartner.includes(partner.partnerRid))}
                    onKeyDown={(event) => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }}

                    onChange={(event, newValue) => {
                      if (event.type === 'click') {
                        const newPartnerRids = newValue.map(item => item.partnerRid);
                        setPage(1);
                        if (JSON.stringify(newPartnerRids) !== JSON.stringify(filterPartner)) {
                          setFilterScheme([]);
                          setPersistentSchemeFilters([]);
                          setSchemeSearchTerm("");
                          setSchemePage(1);
                          setHasMoreSchemes("");
                        }
                        setFilterPartner(newPartnerRids);
                      }
                    }}
                    renderInput={(params) => {
                      const isSearching = params.inputProps.value !== '';
                      return (
                        <TextField
                          {...params}
                          label="Filter By Partner"
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            sx: { '& .MuiAutocomplete-tag': { display: 'none' } },
                            endAdornment: (
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                width: '100%',
                                justifyContent: 'center'
                              }}>
                                {filterPartner.length > 0 && !isSearching ? (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      right: 0,
                                      left: 0,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      height: '100%',
                                      cursor: 'pointer',
                                      padding: '0 32px 0 8px',
                                      paddingRight: '24px',
                                      paddingBottom: '24px'
                                    }}
                                    onClick={(e) => {
                                      const dropdownButton = e.currentTarget.closest('.MuiAutocomplete-root').querySelector('.MuiAutocomplete-popupIndicator');
                                      if (dropdownButton) {
                                        dropdownButton.click();
                                      }
                                    }}
                                  >
                                    <div className="selected-count" style={{
                                      fontSize: '16px',
                                      color: 'rgba(0, 0, 0, 0.87)',
                                      whiteSpace: 'nowrap',
                                      display: 'flex',
                                      alignItems: 'center',
                                      transition: 'opacity 0.2s ease',
                                      pointerEvents: 'none'
                                    }}>
                                      {filterPartner.length} Selected
                                    </div>
                                    <div style={{
                                      position: 'absolute',
                                      right: 0,
                                      display: 'flex',
                                      alignItems: 'center',
                                      height: '100%',
                                      paddingBottom: '2px'
                                    }}>
                                      {params.InputProps.endAdornment}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )
                          }}
                        />
                      );
                    }}
                    renderOption={(props, option) => (
                      <div {...props} className="d-flex align-items-center" style={{
                        padding: '8px 16px',
                        cursor: 'pointer'
                      }}>
                        <Checkbox
                          checked={filterPartner.indexOf(option.partnerRid) > -1}
                          size="small"
                          sx={{
                            color: 'rgba(0, 0, 0, 0.54)',
                            '&.Mui-checked': {
                              color: 'primary.main'
                            }
                          }}
                        />
                        <div className="flex-grow-1" style={{ marginLeft: '8px' }}>
                          <span className="fs-med-medium">{option.fullName}</span>
                        </div>
                      </div>
                    )}
                    sx={{
                      width: { xs: "100%", sm: 180 },
                      minWidth: "180px",
                      "@media (max-width: 600px)": {
                        width: "100%"
                      },
                      '& .MuiAutocomplete-tag': {
                        display: 'none'
                      },
                      '& .MuiAutocomplete-paper': {
                        marginTop: '4px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        paddingRight: '8px !important'
                      }
                    }}
                    disableCloseOnSelect
                    disableClearable
                  />
                </FormControl>
              )}
              <FormControl
                sx={{
                  width: { xs: "100%", sm: 180 },
                  minWidth: "180px",
                  "@media (max-width: 600px)": {
                    width: "100%",
                  },
                }}
                size="small"
              >
                <Autocomplete
                  ref={schemeAutocompleteRef}

                  multiple
                  id="scheme-filter-autocomplete"
                  options={filterSchemeList}
                  ListboxComponent={(props) => {
                    return (
                      <ul
                        {...props}
                        ref={schemeListboxRef}
                        onScroll={hasMoreSchemes === "" ? loadMoreSchemes : undefined}
                        style={{
                          overflowY: "auto",
                          maxHeight: 320,
                          position: "relative",
                          paddingBottom: schemeFilterLoading ? "40px" : "0"
                        }}
                      >
                        {props.children}
                        {schemeFilterLoading && (
                          <div
                            ref={loaderRef}
                            style={{
                              position: "sticky",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "8px 0",
                              backgroundColor: "#fff",
                              boxShadow: "0 -2px 5px rgba(0,0,0,0.05)",
                              zIndex: 1
                            }}
                          >
                            <CircularProgress size={24} />
                            <span style={{ marginLeft: 8, fontSize: 14 }}>Loading more schemes...</span>
                          </div>
                        )}
                      </ul>
                    );
                  }}
                  getOptionLabel={(option) => option.schemeName}
                  value={filterSchemeList.filter(scheme => filterScheme.includes(scheme.schemeRid))}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace') {
                      event.stopPropagation();
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (event.type === 'click') {
                      const newSchemeRids = newValue.map(item => item.schemeRid);
                      handleSchemeFilterChange({ target: { value: newSchemeRids } });
                      if (JSON.stringify(newSchemeRids) !== JSON.stringify(filterScheme)) {
                        setPage(1);
                        getDematHoldings();
                      }
                    }
                  }} loading={schemeFilterLoading}
                  disabled={isLoading}
                  freeSolo={true}
                  inputValue={schemeSearchTerm}
                  onInputChange={(event, newValue, reason) => {
                    if (event && (reason === 'input' || reason === 'clear')) {
                      if (event.target) {
                        schemeInputPositionRef.current = event.target.selectionStart;
                      }
                      setSchemeSearchTerm(newValue);
                      if (newValue) {
                        setIsSearchingScheme(true);
                        debouncedSchemeSearch(newValue);
                      } else {
                        setIsSearchingScheme(false);
                      }
                    }
                  }}
                  filterOptions={(options, params) => {
                    return options;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter By Scheme"
                      size="small"
                      variant="outlined"
                      placeholder=""
                      InputProps={{
                        ...params.InputProps,
                        sx: { '& .MuiAutocomplete-tag': { display: 'none' } },
                        endAdornment: (
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            width: '100%',
                            justifyContent: 'center'
                          }}>
                            {filterScheme.length > 0 && !schemeSearchTerm ? (
                              <div
                                style={{
                                  position: 'absolute',
                                  right: 0,
                                  left: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  height: '100%',
                                  cursor: 'pointer',
                                  padding: '0 32px 0 8px',
                                  paddingRight: '24px',
                                  paddingBottom: '24px'
                                }}
                                onClick={(e) => {
                                  const dropdownButton = e.currentTarget.closest('.MuiAutocomplete-root').querySelector('.MuiAutocomplete-popupIndicator');
                                  if (dropdownButton) {
                                    dropdownButton.click();
                                  }
                                }}
                              >
                                <div className="selected-count" style={{
                                  fontSize: '16px',
                                  color: 'rgba(0, 0, 0, 0.87)',
                                  whiteSpace: 'nowrap',
                                  display: 'flex',
                                  alignItems: 'center',
                                  transition: 'opacity 0.2s ease',
                                  pointerEvents: 'none'
                                }}>
                                  {filterScheme.length} Selected
                                </div>
                                <div style={{
                                  position: 'absolute',
                                  right: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '100%',
                                  paddingBottom: '2px'
                                }}>
                                  {params.InputProps.endAdornment}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        )
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <div {...props} className="d-flex align-items-center" style={{
                      padding: '8px 16px',
                      cursor: 'pointer'
                    }}>
                      <Checkbox
                        checked={filterScheme.indexOf(option.schemeRid) > -1 ||
                          persistentSchemeFilters.indexOf(option.schemeRid) > -1}
                        size="small"
                        sx={{
                          color: 'rgba(0, 0, 0, 0.54)',
                          '&.Mui-checked': {
                            color: 'primary.main'
                          }
                        }}
                      />
                      <div className="flex-grow-1" style={{ marginLeft: '8px' }}>
                        <span className="fs-med-medium">{option.schemeName}</span>
                      </div>
                    </div>
                  )}
                  sx={{
                    width: { xs: "100%", sm: 180 },
                    minWidth: "180px",
                    "@media (max-width: 600px)": {
                      width: "100%"
                    },
                    '& .MuiAutocomplete-tag': {
                      display: 'none'
                    },
                    '& .MuiAutocomplete-paper': {
                      marginTop: '4px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      paddingRight: '8px !important'
                    }
                  }}
                  disableCloseOnSelect
                  disableClearable
                />
              </FormControl>
              <DematStatusFilter
                filterDematStatus={filterDematStatus}
                setFilterDematStatus={setFilterDematStatus}
                isLoading={isLoading}
                onStatusChange={() => {
                  if (tabIndex === 1) {
                    getDematHoldings();
                  }
                }}
              />
              {!isSubAdmin() && (
                <Button
                  sx={{ textTransform: "none" }}
                  onClick={downloadDematHoldings}
                  startIcon={<GetAppIcon />}
                  disabled={isLoading}
                >
                  <span className="fs-med-regular">Export to Excel</span>
                </Button>
              )}
            </div>
            <div style={{ flex: 1, width: "900px" }}></div>
            <div className="ms-auto d-flex align-items-center">

              <Pagination
                color="secondary"
                size="small"
                siblingCount={0}
                onChange={handlePageChange}
                count={Math.max(1, totalRecords > 0 ? Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT) : 1)}
                page={page}
              />
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-column bg-white overflow-hidden">
        <div
          className="d-flex flex-grow-1 bg-white py-2 px-3 overflow-y-scroll "
          style={{ borderRadius: "5px" }}
        >
          {isLoading
            ? [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
            : renderTableContent()}
        </div>
      </div>
    </Layout>
  );
};

export default DematMaster;
